import { MatchesResponse } from './../apiTypes/rbfa.types';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  Competition,
  CompetitionsApi,
  RankingApi,
  Team,
} from "../apiTypes/rbfa.types";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class RbfaService {
  jwtToken: string = localStorage.getItem("RBFA");

  constructor(private httpclient: HttpClient) {}
  //it make api req and get bearer token
  async getNonExpiredToken() {
    const response = await this.httpclient
      .get("https://europe-west1-glxrtcmsapp.cloudfunctions.net/getBearer")
      .toPromise();
    this.jwtToken = response.toString();
    localStorage.setItem("RBFA", this.jwtToken);
  }

  //this sends req to rbfa API if token is expired , it gets new token and send get request again
  async makeGetApiRequest(url: string) {
    const res = await this.httpclient
      .get(url + `&token=${this.jwtToken}`)
      .toPromise()
      .catch(async (err) => {
        if (err.status === 401) {
          await this.getNonExpiredToken();
          const headers = {
            Authorization: `Bearer ${this.jwtToken}`,
          };
          return await this.httpclient
            .get(url + `&token=${this.jwtToken}`)
            .toPromise();
        }
      });

    return res;
  }

  async getClubs() {
    if (localStorage.getItem("RBFA") == null) {
      await this.getNonExpiredToken();
    }

    const res = await this.makeGetApiRequest(
      "https://europe-west1-glxrtcmsapp.cloudfunctions.net/getProxy?page-size=100&club-type=standard&endPointUrl=api/v1/clubs"
    );
    return res;
  }

  async getTeams(clubId: string) {
    if (localStorage.getItem("RBFA") == null) {
      await this.getNonExpiredToken();
    }

    const res = await this.makeGetApiRequest(
      `https://europe-west1-glxrtcmsapp.cloudfunctions.net/getProxy?endPointUrl=api/v1/club/teams&club=${clubId}&page-size=100&season=2024`
    );
    return res;
  }


  async getCompetitions(teamId:string){
    if (localStorage.getItem("RBFA") == null) {
      await this.getNonExpiredToken();
    }

    const competitions = (await this.makeGetApiRequest(
      `https://europe-west1-glxrtcmsapp.cloudfunctions.net/getProxy?endPointUrl=api/v1/team/competitions&team=${teamId}&page-size=100`
    )) as CompetitionsApi;

    return competitions.competitions
  }


  //returns latest ranking for team
  //firstly it finds all the competioons
  //then get that competion which is latest and return it's ranking
  async getLatestRanking(teamId: string) {

    const competitions =await this.getCompetitions(teamId)


    let maxRanking:any={}
    let firstAssigned=false


      if(competitions.length==0){
        return {
          teams:[],
          index:0,//the index to highlight
        };
      }

    await Promise.all(
      competitions.map(async (competition: Competition) => {
        const ranking = (await this.makeGetApiRequest(
          `https://europe-west1-glxrtcmsapp.cloudfunctions.net/getProxy?endPointUrl=api/v1/competition/rankings&competition=${competition.id}&page-size=100`
        )) as RankingApi;
        if (firstAssigned==false && ranking.overall) {
          maxRanking.competition = competition.name;
          maxRanking.date = moment(ranking.overall.calculation_date).toDate();
          maxRanking.ranking = ranking;
          firstAssigned=true
        } else if (ranking.overall &&
          moment(ranking.overall.calculation_date).toDate() > maxRanking.date
        ) {
          maxRanking.competition = competition.name;
          maxRanking.date = moment(ranking.overall.calculation_date).toDate();
          maxRanking.ranking = ranking;
        }
      })
    );



    return {
      competition:maxRanking.competition,
      teams:maxRanking.ranking.overall.teams,
      index:maxRanking.ranking.overall.teams.findIndex((team:Team)=>team.id==teamId),//the index to highlight
    };
  }


  async getCompetitionRanking(competitionId: string,teamId:string) {
    const ranking = (await this.makeGetApiRequest(
      `https://europe-west1-glxrtcmsapp.cloudfunctions.net/getProxy?endPointUrl=api/v1/competition/rankings&competition=${competitionId}&page-size=100`
    )) as RankingApi;
    return {
      teams:ranking.overall.teams,
      index:ranking.overall.teams.findIndex((team:Team)=>team.id==teamId),//the index to highlight
    };
  }

  //get all matches for team
  async getAgenda(teamId:string){
    if (localStorage.getItem("RBFA") == null) {
      await this.getNonExpiredToken();
    }

    const matches = (await this.makeGetApiRequest(
      `https://europe-west1-glxrtcmsapp.cloudfunctions.net/getProxy?endPointUrl=api/v1/team/matches&team=${teamId}&page-size=100`
    )) as MatchesResponse;

    return matches
  }


  async getClubAgenda(clubID:string){
    if (localStorage.getItem("RBFA") == null) {
      await this.getNonExpiredToken();
    }

    const matches = (await this.makeGetApiRequest(
      `https://europe-west1-glxrtcmsapp.cloudfunctions.net/getProxy?endPointUrl=api/v1/club/matches&club=${clubID}&page-size=100`
    )) as MatchesResponse;

    return matches
  }

  //get all matches for competition and highlight the selected team
  async getAgendaCompetition(competitionId:string){
    if (localStorage.getItem("RBFA") == null) {
      await this.getNonExpiredToken();
    }

    const matches = (await this.makeGetApiRequest(
      `https://europe-west1-glxrtcmsapp.cloudfunctions.net/getProxy?endPointUrl=api/v1/competition/matches&competition=${competitionId}&page-size=100`
    )) as MatchesResponse;

    return matches
  }

}